._not_{
    margin-top: 60px !important;
}
.title_noti{
    font-family: 'Angkor', cursive;
    font-size: 18px !important;
}
.fixed_nav{
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    padding:10px 0px 5px 0px !important;
    z-index: 1;
    background-color: #9fcffb;
    -webkit-box-shadow: -2px 10px 16px -8px rgba(0,0,0,0.44);
    -moz-box-shadow: -2px 10px 16px -8px rgba(0,0,0,0.44);
    box-shadow: -2px 10px 16px -8px rgba(0,0,0,0.44);    
}
.btn_home{
    font-family: 'Hanuman', serif;
    font-size: 16px;
    border: 0px;
    background: none;
}