* button:focus,
* input:focus{
    outline: none;
}
._signup_{
    margin-top: 30px;
}
.sgin_form{
    /* background-color: whitesmoke; */
    /* box-shadow: 0px 0px 1px gray; */
    border-radius: 10px;
    padding:10px 0px ;
}
#logo_image_sign{
    width:320px !important;
    height: 100%;
    display:block;
    margin-top: 30px !important;

}
.input_signup{
    font-family: 'Hanuman', serif;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    width: 100%;
    margin-top: 15px;
    padding: 8px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
}
.cont_login_padding{
    display: block;
    padding: 0px 20%;
}

.btnHideShow{
    border: 0px;
    background: none;
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Hanuman', serif;

}
.img_btnHideShow{
    margin-right: 10px;
    margin-top: -5px;
    width: 20px;
}

.input_login:focus ,.btnHideShow:focus{
    outline: 0px;
}
.btnLogin_{
    font-family: 'Hanuman', serif;
    font-size: 18px;
    border: 0;
    width: 60%;
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 10px;
}
.lin_login{
    height:2px;
    width:100%;
    margin:30px 0px;
}
.linkYoutube h1{
    margin-top: 20px !important;
    font-family: 'Hanuman', serif;
    font-size: 14px;
}
.loginwith{
    font-size: 16px;
    font-family: 'Hanuman', serif;
    padding:5px;
    background-color:white;
    margin-top:-45px;
    width:50%;
    display:block;
    margin-left:auto;
    margin-right:auto
}
.image_bntFacebbok{
  width: 100%;
}
._bntFacebbok{
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-width: 0;
    background: none;
    margin-top: 20px;
}
.btnHideShow_signUp{
    border: 0px;
    background: none;
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Hanuman', serif;
}
#maintitle_signup{
    font-family: 'Moul', cursive !important;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
}
@media (max-width: 1199px) { 
    .input_login{
        width: 100%;
    }
}
@media (max-width: 991px){
    .input_signup{
        font-size: 16px !important;
    }
    .btnLogin_{
        font-size: 16px !important;
        width: 60%;
        padding: 8px;
        margin-top: 10px;
    }
}
@media (max-width: 767px){
  
}
@media (max-width: 575px){
    .input_signup{
        border-width: 1px ;
    }
    #maintitle_signup{
        font-family: 'Moul', cursive !important;
        line-height: 30px;
        font-size: 14px;
        text-align: center;
    }
    #logo_image_sign{
        width:220px !important;
        height: 100% !important;
        display:block;
        margin: 0px 10px !important;
        margin-top: 10px !important;
    }
}