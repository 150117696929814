* button:focus,
* input:focus{
    outline: none;
}
* a:hover{
    text-decoration: none;
}
._youble_{
    margin-top: 30px;
}
.background_youtube{
    height: 100% !important;
    background-color: black;
}
.iframe_video{
    width: 100%;
    height: 692px;
    border:0px;
    margin: 0 auto;
    display: block;
}
#logo_image_header_youtube,
#logo_image_result_header_youtube{
    width: 60px;
    margin-right:10px ;
}
@media (max-width: 1199px) { 
 
}
@media (max-width: 991px){

}
@media (max-width: 767px){

}
@media (max-width: 575px){
    .iframe_video{
        width: 320px;
        height: 420px;
        border:0px;
        margin: 0 auto;
        display: block;
    }
    #logo_image_header_youtube,
    #logo_image_result_header_youtube{
        width: 50px !important;
    }
}