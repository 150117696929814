.preview_form #scroll-dialog-title {
      background-color: #cfd8dc;
      height: 60px;
      padding-left: 15px;
}

.preview_form .previewButtom {
      background-color: #cfd8dc;
      height: 60px;
}

.title_verify {
      font-size: 18px;
      font-weight: 500;
      font-family: 'Moul', cursive;
      height: 100%;
}

.preview_form .profileName {
      text-align: center;
}

.preview_form .blog_info_member_profile h4 {
      font-size: 26px;
}

.blog_info_member_profile {
      margin-top: 20px;
      width: 100%;
}

.blog_info_member_passport {
      width: 100%;
      background-color: #e0e0e0;
      margin-top: 10px;
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 30px;
}

.blog_info_member {
      margin-top: 20px;
      background-color: #e0e0e0;
      border-radius: 6px;
      padding: 10px;
}

.button_preview_edit {
      height: 40px;
      background-color: white;
      color: #e91e63 !important;
      border-width: 0px !important;
      border-radius: 6px !important;
      width: 50%;
      box-shadow: none !important;
      font-family: 'Odor Mean Chey', serif;
}

.button_preview_payment {
      height: 40px;
      background-color: #5489F6;
      color: #FFF !important;
      border-width: 0px !important;
      border-radius: 6px !important;
      width: 50%;
      box-shadow: none !important;
      font-family: 'Odor Mean Chey', serif;
}

.button_cancel_preview {
      height: 20px;
}

.txtNumberPreview {
      font-size: 20px;
      font-weight: 600
}

.txtRed {
      color: 'red'
}

.profile_image_pro_preview {
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 132.28346457px;
      height: 170.07874016px !important;
      border: 1px gray solid;
}

.blog_info_member h5 {
      font-size: 20px;
      margin-left: 30px;
      font-family: 'Odor Mean Chey', serif;
}

.blog_info_member h6 {
      margin-top: 15px;
      font-size: 14px;
      font-family: 'Hanuman', serif;
}

.image_review_card {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
}

@media (max-width: 575px) {
      .title_verify {
            font-size: 16px;
      }

      .review_payment {
            margin-top: 20px;
      }

      .blog_info_member {
            margin-top: 10px;
      }

      .blog_info_member h5 {
            font-size: 18px;
            margin-left: 15px;
      }

      .blog_info_member h6 {
            font-size: 14px;
      }

      .image_review_card {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            height: auto;
            overflow: hidden;
            display: flex;
            align-items: center;
      }


}