.lbl_name{
    font-weight: bold;
    font-size: 25px !important; 
    margin-top: 20px;
}
.lbl_show_{
    font-size: 16px;
    font-family: 'Hanuman', serif;
}
.maintitle_result{
    font-size: 25px;
    font-family: 'Moul', cursive;
    line-height: 40px;
}
.maintitle_result_suceess{
    font-size: 25px;
    font-family: 'Moul', cursive;
    line-height: 40px;
    color: green;
}
.maintitle_result_error{
    font-size: 25px;
    font-family: 'Moul', cursive;
    line-height: 40px;
    color: red;
}
.code_exam{
    /* background-color: rgb(199, 199, 199); */
    border-radius: 10px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 0px;
    padding-top: 15px;
}
.cont_result_padding{
    display: block;
}
.cont_result_padding_seasonal{
    display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  height: 80vh; 

}
.lbl_thanks{
    font-family: 'Moul', cursive;
    margin: auto;
    color: green;
    font-size: 30px;
}
.lbl_complete_seasonal{
    font-size: 20px;
    font-family: 'Hanuman', serif;
    margin-top: 20px;
}
.code_{
    margin: 0px !important;
    font-size: 20px !important;
    font-family: 'Hanuman', serif;
}
.lblExam{
    font-size: 14px !important;
    font-family: 'Hanuman', serif;
}
.show_idefication{
    margin-top: 10px;
    margin-bottom: 20px;
}
.code_Job{
    font-size: 16px !important;
    font-family: 'Hanuman', serif;
}

.mainJob{
    font-size: 20px !important;
}
#logo_image_result{
    width: 60px;
    margin:0px  10px !important; 
}
.form_resutl{
    border-radius: 10px;
    padding:10px 0px ;
    margin-top: 100px !important;
}

._Sucess{
    text-align: center;
    background-color: green;
    color: white;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 20px;
    padding-top: 20px;
}
._Error{
    background-color: red;
    color: white;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 20px;
    padding-top: 20px;
    border-radius: 10px;
}
.show_date{
    margin-top: 30px;
}
#btnLogout{
    margin-top: 30px;
    float: right !important;
    background-color: tomato;
    outline: none;
    border: none;
}
#result_id_pf{
    width: 120px;
    margin-top: 20px;
}
._row_buttom_logout{
    float: right;
    margin: 30px !important;
}
@media (max-width: 1199px) { 
    
}
@media (max-width: 991px){
    .maintitle{
        font-size: 16px !important;
    }
    .lbl_thanks{
        font-size: 25px;
    }
    .lbl_complete_seasonal{
        font-size: 16px;
       
    }
}
@media (max-width: 767px){
  .maintitle_result{
      line-height: 25px;
  }
  .maintitle_result_suceess,.maintitle_result_error{
    line-height: 35px;
}
}
@media (max-width: 575px){
    .maintitle{
        font-size: 16px !important;
    }
    #result_id_pf{
        width: 120px;
        margin-top: 20px;
    }
    .text-center{
        text-align: center !important;
    }
    .code_exam{
        width: auto;
        padding: 3px 0px;
        padding-top:15px;
    }
    ._Sucess{
        width: 100%;
        padding: 5px 20px;
        padding-top: 20px;
    }
    .cont_result_padding{
        display: block;
        padding: 0px 10px;
    }
    .cont_result_padding_seasonal{
        display: block;
        padding: 0px 10px;
    }
    .maintitle_result{
        font-size: 16px !important;
    }
    .maintitle_result_error,.maintitle_result_suceess{
        font-size: 16px !important;
        line-height: 25px;
    }
    #logo_image_result{
        width: 40px;
    }
    .form_resutl{
        border-radius: 10px;
        padding:10px 0px ;
        margin-top: 80px !important;
    }
    #btnLogout{
        margin-bottom: 30px !important;
    }
    .cont_result_padding_seasonal{
      display: flex;
      justify-content: center; /* Horizontally center */
      align-items: center; /* Vertically center */
      height: 80vh; 
    
    }
    .lbl_thanks{
        font-size: 20px;
    }
    .lbl_complete_seasonal{
        font-size: 16px;
       
    }
}