* button:focus,
* input:focus{
    outline: none;
}
* a:hover{
    text-decoration: none;
}
._login_{
    margin-top: 30px;
}
.log_form{
    border-radius: 10px;
    padding:10px 0px ;
    margin-top: 60px;
}
.div_logo_login{
  margin-top: 30px;
  margin-left:auto;
  margin-right:auto;
  display:flex;
  justify-content: center
}
#logo_image__{
    margin-top: 10px;
    width:320px !important;
    height: 100%;
    display:block;
    margin-left: 20px;
    margin-right: 20px;
}
.input_login{
    border:1px solid black !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    width: 80%;
    margin-top: 15px;
    padding: 8px;
    border-radius: 10px;
    border-style: solid;
    font-family: 'Hanuman', serif;
}
.cont_login_padding{
    display: block;
    padding: 0px 20%;
}

.btnHideShow{
    border: 0px;
    background: none;
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Hanuman', serif;
    margin-right: 10%;

}
._login_control_{
    padding-top:50px;
    margin-top: 50px !important;
}
.img_btnHideShow{
    margin-right: 10px;
    margin-top: -5px;
    width: 20px;
}

.input_login:focus ,.btnHideShow:focus{
    outline: 0px;
}
.btnLogin_{
    font-family: 'Hanuman', serif;
    font-size: 18px;
    border: 0;
    width: 60%;
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 10px;
}
.lin_login{
    height:2px;
    width:100%;
    margin:30px 0px;
}
.linkYoutube h1{
    font-size: 14px;
    font-family: 'Hanuman', serif;
    color: black;
    text-decoration: underline;
}
.loginwith{
    font-size: 16px;
    font-family: 'Hanuman', serif;
    padding:5px;
    background-color:white;
    margin-top:-45px;
    width:50%;
    display:block;
    margin-left:auto;
    margin-right:auto
}
.image_bntFacebbok{
  width: 100%;
}
._bntFacebbok{
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-width: 0;
    background: none;
    margin-top: 20px;
}
* button:focus{
    outline: none;
}
.cont_login_padding p{
    font-size: 13px;
    word-wrap: break-word;
    width: 80%;
    margin: 0px auto;
    color: rgb(84, 137, 245);
    font-family: 'Hanuman', serif;
}
@media (max-width: 1199px) { 
    .input_login{
        font-size: 18px;
        width: 100%;
    }
    .btnHideShow{
        margin-right: 0%;
    
    }
}
@media (max-width: 991px){
    #logo_image__{
        width:160px;
        height: 100%;
    }
    .input_login{
        width: 100%;
    }
}
@media (max-width: 767px){
    .input_login{
        width: 100%;
    }
}
@media (max-width: 575px){
    #logo_image__{
        margin-top: 10px;
        width:220px !important;
        height: 100% !important;
        display:block;
        margin-left: 5px;
        margin-right: 5px;
    }
    .cont_login_padding{
        padding:0px 30px !important
    }
    .btnLogin_{
        font-size: 16px !important; 
        border: 0;
        width: 60% !important;
        padding: 8px !important;
    }
    .input_login{
        font-size: 14px !important;
        border-width: 1px;
    }
    .btnHideShow{
        border: 0px;
        margin-top: 5px;
        font-size: 12px !important;
        font-family: 'Hanuman', serif;
    }
    .linkYoutube h1{
        text-align: center;
        font-size: 12px !important;
        font-family: 'Hanuman', serif;
        color: black;
        text-decoration: underline;
    }
    .cont_login_padding p{
        font-size: 13px;
        word-wrap: break-word;
        width: 100%;
        margin: 0px auto;
        color: rgb(84, 137, 245);
    }
}