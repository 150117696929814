* button:focus,
* input:focus {
    outline: none;
}

* a:hover {
    text-decoration: none;
}

* button:focus {
    outline: none;
}

.modal-content {
    background-color: initial !important;
    border: 0px !important;

}

#iconClosne__ {
    font-size: 30px;
    color: #fff;
}

.borderColonmPay {
    border-left: 1px;
    border-left-color: #aaaaab;
    border-left-style: solid;
}

.modal-body {
    padding: 5px;
    background-color: #EDEDED;
}

.dropdown-toggle::after {
    float: right;
    font-size: 20px;
    color: rgb(41, 41, 41);
    margin-top: 10px;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    outline: none !important;
    box-shadow: none;
}

.drop_down_gender {
    margin-top: 10px;
    width: 100%;
    background: none;
    color: black;
    text-align: left;
    border: 1px solid gray;
    padding: 8px;
    font-size: 18px;
    border-radius: 10px;
}

.drop_down_gender:hover {
    background: none;
    color: black;
    border: 1px solid gray;
    outline: none !important;

}

.drop_down_gender:focus {
    box-shadow: none;
    outline: none !important;
}

.dropdown {
    outline: none !important;
}

.show {
    outline: none !important;
}

.drop_down_gender:focus {
    background: none !important;
    color: black !important;
    border: 1px solid gray !important;
    outline: none !important;

}

.drop_down_gender:active {
    background: none !important;
    outline: none !important;

    color: black !important;
    border: 1px solid gray !important;
}

.form_submit {
    border-radius: 10px;
    padding: 10px 0px;
    margin-top: 100px !important;
    font-family: 'Hanuman', serif;
}

.form_submit h1 {
    text-align: center;
    font-size: 20px;
    font-family: 'Hanuman', serif;
}

._day_home {
    padding: 0px;
    padding-right: 20px;
}

._month_home {
    padding: 0px 10px;
}

._year_home {
    padding: 0px;
    padding-left: 20px;
}

.check_agre {
    margin-top: 20px;
}

.profile_image {
    width: 100%;
    border: 2px solid gray;

}

.div_profileImage {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.lbl_ {
    margin-top: 20px;
    text-align: left;
}

.button_uplageProfile {
    cursor: pointer;
    width: 160px;
    margin-top: 10px;
    padding: 5px 10px;
    color: white;
    background-color: gray;
    border-radius: 5px;
}

#upload-photo {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.div_identify {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.bntPayment {
    width: 100%;
    border: 0px;
    background: none;
    background-color: white;
    padding: 10px 0px;
    margin-top: 10px;
    text-align: center;
    border-radius: 0px;
    -webkit-box-shadow: -1px 2px 12px 3px rgba(196, 196, 196, 1);
    -moz-box-shadow: -1px 2px 12px 3px rgba(196, 196, 196, 1);
    box-shadow: -1px 2px 12px 3px rgba(196, 196, 196, 1);
}

.bntPayment:hover {
    background: none;
}

.bntPayment img {
    height: 80px;
}

#logo_image_header {
    width: 60px;
}

.input_form {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    width: 100%;
    margin-top: 15px;
    padding: 8px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
}

.homepage {
    margin-top: 100px !important;
    text-align: center;
}

.payemnt_type {
    margin-bottom: 50px;
}

.profile_image_pro {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 132.28346457px;
    height: 170.07874016px !important;
    border: 2px gray solid;
}

.bodayPayment {
    text-align: center;
    padding: 20px;
}

.imagePayment_logo {
    width: 20%;
}

.bodayPayment h3 {
    font-size: 20px;
}

.bodayPayment h3 span {
    font-size: 25px;
}

.bodayPayment .input_bodyPayment {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    width: 60%;
    margin-top: 15px;
    padding: 8px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
}

.maintitle {
    font-family: 'Moul', cursive !important;
    line-height: 30px;
    font-size: 19px;
}

.titleButtom_ {
    font-size: 16px !important;
}

.title_above_paymenth {
    display: 'block';
    font-size: 14px;
    color: gray;
    font-weight: lighter;
}
.form_submit_button_back{
    text-align: center;
    padding: 0px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form_submit_button_back{
    font-family: 'Hanuman', serif;
    font-size: 18px;
    border: 0;
    width: 60%;
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    margin-top: 10px;
    align-items: center;
}
.form_submit_button_back_ico{
    margin-top: -4px;
    margin-right: 10px;
}
.dev_btn_close_wing{
    /* width: ; */
}
@media (max-width: 1199px) {}

@media (max-width: 991px) {
    .div_identify {
        width: 90%;
        height: auto;
    }

    .maintitle {
        font-size: 16px !important;

    }

    .input_form {
        font-size: 14px !important;
        border-radius: 5px;
    }

    ._day_home {
        padding-right: 5px;
    }

    ._month_home {
        padding: 0px 5px;
    }

    ._year_home {
        padding-left: 5px;
    }

    .drop_down_gender {
        padding: 5px;
        font-size: 14px;
        border-radius: 5px;
    }

}

@media (max-width: 767px) {
    .borderColonmPay {
        border-left: 0px;
    }
}

@media (max-width: 575px) {

    .div_identify {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .maintitle {
        font-size: 14px !important;
    }

    .input_form {
        font-size: 14px !important;
        border-radius: 5px;
    }

    ._day_home {
        padding-right: 5px;
    }

    ._month_home {
        padding: 0px 5px;
    }

    ._year_home {
        padding-left: 5px;
    }

    .drop_down_gender {
        padding: 8px 10px;
        border-color: black;
        border-width: 1px;
    }

    #logo_image_header {
        width: 50px !important;
    }
    .title_above_paymenth{
        font-size: 14px;
    }
}