.loadingNig{
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 10000000;
    background-color: white;
}
#boutner{
    /* z-index: 100000000;
    position: absolute; */
}
.rai-activity-indicator{
    display: block !important;
}
.h5_loading{
    text-align: center;
    font-size: 18px !important;
    font-family: 'Odor Mean Chey', serif;   
}
.rai-windill{
    text-align: center;
}