* button:focus,
* input:focus{
    outline: none;
}
#btnLogout_icon{
    margin-top: 10px;
    font-size: 30px;
    margin-right: -100px;
    color:red
}
.control_header{
    padding-bottom: 20px !important;
}
.row{
    padding:0px !important;
    margin: 0px !important;
}
.btn_create_acc_login{
    border: 0px;
    background: none;
    padding: 10px;
}
.btn_create_acc_login h1{
    font-size: 16px !important;
    font-family: 'Kdam Thmor', cursive;
    /* font-family: 'Angkor', cursive; */
}
.create_new_acc{
    color: black;
    font-size: 25px !important;
}
._buttonNotification{
    border: 0px;
    color: black;
    background: none;
}
.icon_notfication{
    width: 35px;
    margin-top: 1px !important;

}
#logo_image_result_header{
    width: 140px;
    margin:0px  10px !important; 
}
#logo_image_result_header_seasonal{
    width: 60px;
    margin:0px  10px !important; 
}
#button_back_nav {
    font-size: 16px;
    font-family: 'Hanuman', serif;
    color: red;
}

/* -----------outline-------------- */
.btn_create_acc_login:focus{
    outline: 0px;
}
._buttonNotification:hover{
    border: 0px;
    color: black;
    background: none;
}

@media (max-width: 1199px) { 
    .icon_notfication{
        margin-top: 10px !important;
        width: 40px;
    }
}
@media (max-width: 991px){
    .icon_notfication{
        margin-top: 10px !important;
        width: 40px;
    }
}
@media (max-width: 767px){
    .btn_create_acc_login h1{
        font-size: 14px !important;
    }
    .icon_notfication{
        width: 30px;
    }
    .icon_notfication{
        margin-top: 12px !important;
        width: 30px;
    }
}
@media (max-width: 575px){
    .row{
        padding: 0px ;
        margin: 0px;
    }
    .icon_notfication{
        margin-top: 5px !important;
        width: 30px;
    }
    .btn_create_acc_login h1{
        font-size: 14px !important;
    }
    #col___l{
        text-align: left !important;
    }
    #col___f{
        text-align: right !important;
    }
    #btnLogout_icon{
        margin-top: 8px;
        font-size: 20px;
        margin-right: 0px;
        color:red
    }
    
#button_back_nav {
    font-size: 12px;
}
#logo_image_result_header_seasonal{
    width: 50px;
}
}