.btnReload{
    border: 0px;
    background: none;
    padding: 20px;
    margin-top: 30px;
    font-family: 'Odor Mean Chey', serif;   
}
.div_logo_schandule{
  margin-top: 30px;
  margin-bottom: 30px;

  margin-left:auto;
  margin-right:auto;
  display:flex;
  justify-content: center
}
#logo_image_div_logo_schandule{
    margin-top: 30px;
    height: 100%;
    width:220px !important;
    display:block;
    margin-left: 20px;
    margin-right: 20px;
}
#logo_image_div_logo_schandule_swp{
    margin-top: 30px;
    height: 100%;
    width:160px !important;
    display:block;
    margin-left: 20px;
    margin-right: 20px;
}

.cont_login_padding_noSchadule h4{
    margin-bottom: 20px;
    font-family: 'Moul', cursive;
}
.cont_login_padding_noSchadule h5{
    line-height: 40px;
    font-family: 'Odor Mean Chey', serif;
}
@media (max-width: 1199px) { 
   
}
@media (max-width: 991px){
    #logo_image_div_logo_schandule{
        width:100px;
  
}
}
@media (max-width: 767px){
   
}
@media (max-width: 575px){
    #logo_image_div_logo_schandule{
        width:100px !important;
    }
    .cont_login_padding_noSchadule h4{
        margin-bottom: 20px;
        font-size: 18px !important;
    }
    .cont_login_padding_noSchadule h5{
        margin-bottom: 20px;
        font-size: 16px !important;
    }
}